
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import React, { useEffect, useRef, useState } from "react";
import {
  MainContainer,
  Sidebar,
  Search,
  ConversationList,
  Conversation,
  Avatar,
  ChatContainer,
  ConversationHeader,
  VoiceCallButton,
  Message,
  MessageInput,
  VideoCallButton,
  InfoButton,
  MessageSeparator,
  TypingIndicator,
  MessageList
} from "@chatscope/chat-ui-kit-react";
import { Client } from "@stomp/stompjs";
import { User } from "../types/User.type";
import { Msg } from "../types/Msg.type";
import axios from "axios";

export const Main = () => {
  const [loadingMore, setLoadingMore] = useState<boolean>(false);
  const [messageInputValue, setMessageInputValue] = useState("");
  const user = JSON.parse(localStorage.getItem('user') || '');
  const [users, setUsers] = useState<User[]>([]);
  const [msgs, setMsgs] = useState<Msg[]>([]);
  const [chattingOpponentUser, setChattingOpponentUser] = useState<User>({});
  const [typing, setTyping] = useState<boolean>(false);
  const client = useRef<any>();

  const init = () => {
    client.current = new Client({
      brokerURL: `${process.env.REACT_APP_WS_PROTOCAL}://${process.env.REACT_APP_HOST}/chat`,
      onConnect: () => {
        client.current.subscribe(`/topic/enter-chat`, (data:any) => {
          const tmpUsers = JSON.parse(data.body);
          setUsers(tmpUsers);
        });
        client.current.subscribe(`/topic/chat/${user.user.memberNum}`, (data:any) => {
          const msg = JSON.parse(data.body);
          console.log(msg);
          setTyping(msg.cmiIsTyping);
          if(msg.cmiMessage) {
            setMsgs(msgs => [...msgs, msg]);
          }
        });
      },
      onDisconnect: () => {
  
      },
      connectHeaders: {
        Authorization: `Bearer ${user.jwt}`,
        memberNum: user.user.memberNum,
        userRole: user.user.authorities[0].authority
      }
    });
    // WebSocketConfig에 있는 endpoint에 연결함, 연결되면 WebSocketEventListener에 connectionListener 실행됨
    client.current.activate();
  }

  const scrollChat = (evt:any) => {
    if(loadingMore) {
      return;
    }
    setLoadingMore(true);
    setLoadingMore(false);
  }

  // 메시지 보냄
  const publishMsg = () => {
    const msg = {
      cmiSenderUiNum: user.user.memberNum,
      cmiMessage: messageInputValue,
      cmiReceiveUiNum: chattingOpponentUser.memberNum,
      cmiSender: user.user.memberName
    }
    console.log(client.current);
    if(!client.current.connected) return;
    client.current.publish({
      destination: `/publish/chat/${chattingOpponentUser.memberNum}`,
      body: JSON.stringify(msg)
    });
    setMsgs(msgs => [...msgs, msg]);
    setMessageInputValue('');
  }


  // 상대방이 타이핑중인지 보여줌
  const showIsTyping = (typing:boolean) => {
    if(!client.current.connected) return;
    client.current.publish({
      destination: `/publish/chat/${chattingOpponentUser.memberNum}`,
      body: JSON.stringify({
        cmiSenderUiNum: user.user.memberNum,
        cmiReceiveUiNum: chattingOpponentUser.memberNum,
        cmiIsTyping: typing
      })
    })
  }
  
  // 채팅 리스트 가져옴
  const getChatList = async () => {
    const res = await axios.get(`${process.env.REACT_APP_HTTP_PROTOCAL}://${process.env.REACT_APP_HOST}/chat-list?cmiSenderUiNum=${chattingOpponentUser.memberNum}&cmiReceiveUiNum=${user.user.memberNum}`, 
    {
      headers: {
        Authorization: `Bearer ${user.jwt}`
      }
    });
    console.log(res);

    // 최근것 부터 가져오게 하려고 이렇게 함
    setMsgs(res.data.list.reverse());
  }

  // chattingOpponentUser가 바뀔때마다 채팅 리스트가 바뀜, 
  // setChattingOppeonentUser를 하고 바로 getChatList를 하면 
  // chattingOpponentUser가 바뀌기 전에 getChatList가 실행되서 
  // useEffect에 chattingOpponentUser를 넣어줌
  useEffect(() => {

    // 메인 페이지에 처음 들어왔을때는 chattingOpponentUser.memberNum이 없으므로 실행하지 않음
    if(chattingOpponentUser.memberNum) {
      getChatList();
    }

  }, [chattingOpponentUser]);

  useEffect(() => {
    init();
  }, []);

  return (
    <div className="auth-wrapper">
      <div style={{height: "600px", position: "relative"}}>
        <MainContainer responsive>
          <Sidebar position="left" scrollable={false}>
            <Search placeholder="Search..." />
            <ConversationList>
              {users.map((user, idx) => (
                <Conversation 
                  key={idx}
                  name={user.memberName}
                  lastSenderName={user.memberName}
                  info="asdasdasd"
                  style={{justifyContent: "start"}}
                  onClick={() => setChattingOpponentUser({
                    ...chattingOpponentUser,
                    memberName: user.memberName,
                    memberNum: user.memberNum,
                    loginDate: user.loginDate
                  })}
                >
                  <Avatar
                    src={require('./images/ram.png')}
                    name="Lilly"
                    status={user.login ? 'available' : 'dnd'}
                  />
                </Conversation>
              ))}
            </ConversationList>
          </Sidebar>

          <ChatContainer>
            <ConversationHeader>
              <ConversationHeader.Back />
              <Avatar src={require("./images/ram.png")} name={user.user.memberName} />
              <ConversationHeader.Content
                userName={chattingOpponentUser.memberName}
                info={chattingOpponentUser.loginDate}
              />
              <ConversationHeader.Actions>
                <VoiceCallButton />
                <VideoCallButton />
                <InfoButton />
              </ConversationHeader.Actions>
            </ConversationHeader>
            <MessageList
              onYReachStart={scrollChat}
              loadingMore={loadingMore}
              typingIndicator={typing ? <TypingIndicator content={`${chattingOpponentUser.memberName} is typing`} /> : ''}
            >
              <MessageSeparator content="Saturday, 30 November 2019" />
              {
                msgs.map((msg, idx) => (
                  <Message
                    key={idx}
                    model={{
                      message: msg.cmiMessage,
                      sentTime: msg.cmiSentTime,
                      sender: msg.cmiSender,
                      direction: user.user.memberNum === msg.cmiSenderUiNum ? "outgoing" : "incoming",
                      position: "normal"
                    }}
                    avatarSpacer={user.user.memberNum === msg.cmiSenderUiNum}
                  >
                    {user.user.memberNum === msg.cmiSenderUiNum ? '' : <Avatar src={require("./images/ram.png")} name="Zoe" />}
                  </Message>
                ))
              }
            </MessageList>
            <MessageInput
              placeholder="Type message here"
              value={messageInputValue}
              onChange={(val) => {
                setMessageInputValue(val);
                if(val.length) {
                  showIsTyping(true);
                } else {
                  showIsTyping(false);
                }
              }}
              onSend={publishMsg}
            />
          </ChatContainer>

          {/* <Sidebar position="right">
            <ExpansionPanel open title="INFO">
              <p>Lorem ipsum</p>
              <p>Lorem ipsum</p>
              <p>Lorem ipsum</p>
              <p>Lorem ipsum</p>
            </ExpansionPanel>
            <ExpansionPanel title="LOCALIZATION">
              <p>Lorem ipsum</p>
              <p>Lorem ipsum</p>
              <p>Lorem ipsum</p>
              <p>Lorem ipsum</p>
            </ExpansionPanel>
            <ExpansionPanel title="MEDIA">
              <p>Lorem ipsum</p>
              <p>Lorem ipsum</p>
              <p>Lorem ipsum</p>
              <p>Lorem ipsum</p>
            </ExpansionPanel>
            <ExpansionPanel title="SURVEY">
              <p>Lorem ipsum</p>
              <p>Lorem ipsum</p>
              <p>Lorem ipsum</p>
              <p>Lorem ipsum</p>
            </ExpansionPanel>
            <ExpansionPanel title="OPTIONS">
              <p>Lorem ipsum</p>
              <p>Lorem ipsum</p>
              <p>Lorem ipsum</p>
              <p>Lorem ipsum</p>
            </ExpansionPanel>
          </Sidebar> */}
        </MainContainer>
      </div>
    </div>
  );
}
