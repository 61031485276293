import { ChangeEvent, useState } from "react"
import { User } from "../types/User.type";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export const SignUp = ()=>{
    const [error, setError] = useState<boolean>(false);
    const [chatUser, setChatUser] = useState<User>({});
    const [errMsg, setErrMsg] = useState<string>('');
    const navigate = useNavigate();
    const join = async () => {
        if(!chatUser.memberId) {
            setErrMsg('아이디를 입력해주세요');
            return;
        }
        try {
            const res = await axios.post('https://saengbang.xyz/join', chatUser, {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8'
                }
            });
            console.log(res);
            alert('가입 완료');
            navigate('/sign-in');
        } catch(err) {
            console.log(err);
            setError(true);
        }
    }
    const changeUser = (evt: ChangeEvent<HTMLInputElement>) => {
        setChatUser({
            ...chatUser,
            [evt.target.id]: evt.target.value
        })
    }
    return (
        <div className="auth-wrapper">
            <div className="auth-inner">
                <form>
                    <h3>Sign Up</h3>
                    <div className="mb-3">
                        <div className="text-danger">
                            {errMsg !== '' 
                            ? 
                            errMsg : ''}
                        </div>
                        <label>ID</label>
                        <input type="text" id='memberId' className="form-control" placeholder="아이디" onChange={changeUser} value={chatUser.userId} />
                    </div>
                    <div className="mb-3">
                        <label>Password</label>
                        <input type="password" id='memberPassword' className="form-control" placeholder="비밀번호" onChange={changeUser} />
                    </div>
                    <div className="mb-3">
                        <label>이름</label>
                        <input type="text" id='memberName' className="form-control" placeholder="이름" onChange={changeUser} />
                    </div>
                    <div className="mb-3">
                        <label>전화번호</label>
                        <input type="text" id='memberPhone' className="form-control" placeholder="전화번호" onChange={changeUser} />
                    </div>
                    <div className="mb-3">
                        <label>이메일</label>
                        <input type="email" id='memberEmail' className="form-control" placeholder="이메일" onChange={changeUser} />
                    </div>
                    <div className="mb-3">
                        <label>주소</label>
                        <input type="text" id='memberAddress' className="form-control" placeholder="주소" onChange={changeUser} />
                    </div>

                    <div className="d-grid">
                        <button type="button" className="btn btn-primary" onClick={join}>
                            회원가입
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}