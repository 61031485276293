import axios from 'axios';
import { ChangeEvent, useEffect, useState } from 'react';
import { User } from '../types/User.type';
import { useNavigate } from 'react-router-dom';

export const Login = () => {
  const [chatUser, setChatUser] = useState<User>({});
  const [rememberId, setRememberId] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const navigate = useNavigate();
  useEffect(() => {
    let userId: any = localStorage.getItem('userId');
    if (userId) {
      setChatUser({
        ...chatUser,
        userId: userId
      })
      setRememberId(true);
    }
  }, [])
  const checkRememberId = (evt: any) => {
    setRememberId(evt.target.checked);
  }
  const changeUser = (evt: ChangeEvent<HTMLInputElement>) => {
    setChatUser({
      ...chatUser,
      [evt.target.id]: evt.target.value
    })
  }
  const login = () => {
    axios.post(`${process.env.REACT_APP_HTTP_PROTOCAL}://${process.env.REACT_APP_HOST}/api/login`, chatUser, {
      headers: {
        'Content-Type': 'Application/json;charset=UTF-8'
      }
    }).then(res => {
      alert('로그인이 완료 되었습니다.')
      localStorage.setItem('user', JSON.stringify(res.data));
      if (rememberId) {
        localStorage.setItem('userId', res.data.userId);
      } else {
        localStorage.removeItem('userId');
      }
      navigate('/main');
    }).catch(err => {
      setError(true);
      console.log(err);
    });
  }
  return (
    <div className="auth-wrapper">
      <div className="auth-inner">
        <form>
          <h3>Sign In</h3>
          <div className="mb-3">
            {error
              ?
              <div className='text-danger'>
                아이디와 비밀번호를 확인해주세요.
              </div> : ''
            }
            <label>ID</label>
            <input type="text" id='userId' className="form-control" placeholder="아이디" onChange={changeUser} value={chatUser.userId || ""} />
          </div>

          <div className="mb-3">
            <label>Password</label>
            <input type="password" id='userPassword' className="form-control" placeholder="비밀번호" onChange={changeUser} />
          </div>

          <div className="mb-3">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                id="customCheck1" onChange={checkRememberId} checked={rememberId} />
              <label className="custom-control-label" htmlFor="customCheck1">
                아아디 기억하기
              </label>
            </div>
          </div>

          <div className="d-grid">
            <button type="button" className="btn btn-primary" onClick={login}>
              Sign In
            </button>
          </div>
          <p className="forgot-password text-right">
            <a href="#" onClick={() => navigate('/sign-up')}>회원가입</a>
          </p>
        </form>
      </div>
    </div>
  )
}
